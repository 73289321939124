<template>
    <div class="init">
        <el-form :model="fromValue" label-width="100px" class="elForm" :rules="rules" ref='form_' label-position="top">
                <el-form-item label="下拉选着" prop="tenantId">
                    <el-col :span="8">
                        <el-select v-model="fromValue.tenantId" filterable :placeholder="$t('inputTit.xuanze')">
                            <el-option v-for="item in shopList" :key="item.id"
                            :label="item.name"
                            :value="item.id" />
                        </el-select>
                    </el-col>
                </el-form-item>
                <el-form-item label="单选开关" prop="appName">
                    <el-checkbox-group v-model="fromValue.appName">
                        <el-checkbox label="Option1" border />
                        <el-checkbox label="Option2" border/>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
    </div>
</template>
<script setup>
    import { ref,defineExpose,reactive,defineEmits,getCurrentInstance } from 'vue'
    import { userStore } from '@/store/user'
    // import addrView from './addr.vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    const { $t } = getCurrentInstance().proxy;
    
    const fromValue = reactive({ 
        appId:'',
        tenantId: '',appName:'',appKey:'',appSecret:'',
        rechargeCallbackUrl:'',withdrawCallbackUrl:'',nftDeployCallbackUrl3:'',
        nftMintCallbackUrl3:'',nftRechargeCallbackUrl2:'',nftWithdrawCallbackUrl2:'',
        newGoogleKey:'',ipWhite:''
    })
    const isPlatform = ref(null);isPlatform.value = userStore().getIsPlatform
    fromValue.tenantId = userStore().getUserInfo[0].tenantId;
    const shopList = ref([])

    const form_ = ref(null)
    const rules = ref(null); rules.value = addRules.mainRules
    const style_ = ref(0)

    const emit = defineEmits(['successFun'])
    const completeFun = ()=>{
        if (!form_.value) return
        form_.value.validate((e) => {
            if(e){
                if(style_.value === 0){
                    let obj_ = JSON.parse(JSON.stringify(fromValue))
                    delete obj_.configId
                    Api_.addAppinfoList(obj_).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succAdd'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }else{
                    Api_.updateAppinfoList(fromValue).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succUpdate'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }
            }
        })
    }
    const init_ = (e,obj,shopList_)=>{//初始化数据
        shopList.value = shopList_
        style_.value = e;
        if(obj){
            for(let index in fromValue){
                fromValue[index] = obj[index]
            }
        }else{
            for(let index in fromValue){
                fromValue[index] = ''
            }
        }
    }
    defineExpose({init_,completeFun})
</script>
<style lang="scss" scoped>
    .init{
        max-height: 55vh;
        overflow: auto;
        &::-webkit-scrollbar { width: 0 !important }
    }
</style>