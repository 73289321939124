<template>
    <div>
        <fromSearch :object_='object_' @searchFun='getList'/>
        <avue-crud :option="tableOption" :data="page.list" :page="page" :table-loading="listLoading"
            @size-change="sizeChange"
            @current-change="currentChange">
            <template #menu-left>
                <el-button v-if="roleManager_btn_add" class="filter-item"
                    type="primary"   @click="openView(0)">{{$t('button.add')}} </el-button>
            </template>
            <template #menu="scope">
                <el-button
                    v-if="sys_user_edit" text type="primary" size="small"  
                    @click="openView(1,scope.row)" >{{$t('button.update')}} </el-button>
            </template>
        </avue-crud>
        <el-dialog v-model="dialogFormVisible" :title='isStyleText[isStyle]' width="600">
            <bindView ref='bindView_' @successFun='successFun'/>
            <template #footer>
                <el-button    @click="dialogFormVisible = false">{{$t('button.closeQ')}} </el-button>
                <el-button type="primary"   @click="completeFun">{{$t('button.tijiao')}}</el-button>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
    import { ref,nextTick,getCurrentInstance } from 'vue'
    import { tableOption1 } from "@/const/crud/platform/access";
    
    import mixins from '@/views/mixins/page'
    const { $t } = getCurrentInstance().proxy;
    let pageObj = mixins(['getUserappinfoList'])
    const {page,dialogFormVisible,listLoading,sizeChange,currentChange,getList,successFun} = pageObj
    const isStyle = ref(0)
    const isStyleText = [$t("button.add"),$t("button.update")]
    import bindView from './bind.vue'
    const bindView_ = ref(null)

    import fromSearch from '@/components/fromSearch.vue'
    import { appInface } from '@/const/from/platform/appInface'
    const object_ = ref(appInface);
    
    const tableOption = ref({});tableOption.value = tableOption1

    const roleManager_btn_add = ref(true)
    const sys_user_edit = ref(true)
    const shopList = ref([])

    const openView = (num,parm = {})=>{
        isStyle.value = num
        dialogFormVisible.value = true
        nextTick(()=>{
            bindView_.value.init_(num,parm,shopList.value)
        })
    }
    const completeFun = ()=>{
        bindView_.value.completeFun()
    }
    getList(1)
</script>


<style lang="scss" scoped>
    :deep(.el-dialog__body) {
       padding-top: 0;
    }
</style>